import React, { useState, useEffect } from "react";
import ReactmapGL, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from "react-map-gl";
import RoomIcon from "@mui/icons-material/Room";
import Star from "@mui/icons-material/Star";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import { formatDistanceToNow } from "date-fns";
import { parseISO } from "date-fns";
import "./app.css";

const App = () => {
  const currentUser = "Cristi";
  const [pins, setPins] = useState([]);
  const [currentPlaceId, setCurrentPlaceId] = useState(null);
  const [newPlace, setNewPlace] = useState(null);

  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [rating, setRating] = useState(0);

  const [viewport, setViewport] = useState({
    latitude: 46,
    longitude: 17,
    zoom: 4,
  });

  useEffect(() => {
    const getPins = async () => {
      try {
        const res = await axios.get("https://tricky-knickers-toad.cyclic.app/api/pins");
        setPins(res.data);
      } catch (err) {
        // Handle network errors or other exceptions
        console.error("Error while fetching data:", err);
      }
    };
    getPins();
  }, []);

  const handleMarkerClick = (markerPlaceId, lat, long) => {
    if (currentPlaceId === markerPlaceId) {
      setCurrentPlaceId(null);
    } else {
      setCurrentPlaceId(markerPlaceId);
      // Use the useMap() hook to get a reference to the Map object.
      setViewport({ ...viewport, latitude: lat, longitude: long });
    }
  };

  const handleMarkerPopupClose = (markerPlaceId) => {
    if (currentPlaceId === markerPlaceId) {
      console.log("Closing marker");
      setCurrentPlaceId(null);
    } else {
      console.log("Opening marker");
      setCurrentPlaceId(markerPlaceId);
    }
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    const { lat, lng } = e.lngLat;
    setNewPlace({
      lat,
      long: lng,
    });
  };

  const handleNewPopupClose = () => {
    setNewPlace(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPin = {
      username: currentUser,
      title,
      desc,
      rating,
      lat: newPlace.lat,
      long: newPlace.long,
    };
    try {
      const res = await axios.post("https://tricky-knickers-toad.cyclic.app/api/pins", newPin);
      setPins([...pins, res.data]);
      setNewPlace(null);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="App">
        <ReactmapGL
          mapboxAccessToken={process.env.REACT_APP_MAPBOX}
          // {...viewport}
          initialViewState={{
            longitude: 17,
            latitude: 46,
            zoom: 4,
          }}
          style={{ width: "100vw", height: "100vh" }}
          mapStyle="mapbox://styles/cristianbad767/clnyq797h00b101qq1kl2ath0"
          transitionDuration="200"
          onDblClick={handleAddClick}
          fog={false}
        >
          <GeolocateControl position="top-left" />
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <ScaleControl position="top-left" />
          {pins.map((p) => {
            const createdAtDate = parseISO(p.createdAt);
            const timeAgo = formatDistanceToNow(createdAtDate);
            // console.log(currentPlaceId);
            return (
              <>
                <Marker
                  onClick={(e) => {
                    e.originalEvent.stopImmediatePropagation();
                    handleMarkerClick(p._id, p.lat, p.long);
                  }}
                  key={p._id}
                  longitude={p.long}
                  latitude={p.lat}
                  onClose={() => handleMarkerPopupClose(p._id)}
                  offsetLeft={-viewport.zoom * 3.5}
                  offsetTop={-viewport.zoom * 7}
                >
                  <RoomIcon
                    style={{
                      fontSize: viewport.zoom * 10,
                      color: p.username === currentUser ? "pink" : "red",
                      cursor: "pointer",
                    }}
                  />
                </Marker>
                {p._id === currentPlaceId && (
                  <Popup longitude={p.long} latitude={p.lat} anchor="left">
                    <div className="card">
                      <label>Place</label>
                      <h4 className="place">{p.title}</h4>
                      <label>Review</label>
                      <p className="description"> {p.desc}</p>
                      <label>Rating</label>
                      <div className="stars">
                        {Array(p.rating).fill(<Star className="star" />)}
                      </div>
                      <label>Information</label>
                      <span className="username">
                        Created by <b>{p.username}</b>
                      </span>
                      <span className="date">{timeAgo} ago</span>
                    </div>
                  </Popup>
                )}
              </>
            );
          })}
          {newPlace && (
            <Popup
              longitude={newPlace.long}
              latitude={newPlace.lat}
              anchor="left"
              onClose={handleNewPopupClose}
            >
              <div>
                <form onSubmit={handleSubmit}>
                  <label>Title</label>
                  <input
                    placeholder="Enter a title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <label>Review</label>
                  <textarea
                    placeholder="Say us something about this place"
                    onChange={(e) => setDesc(e.target.value)}
                  />
                  <label>Rating</label>
                  <select onChange={(e) => setRating(e.target.value)}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                  <button className="submitButton" type="submit">
                    Add Pin
                  </button>
                </form>
              </div>
            </Popup>
          )}
        </ReactmapGL>
      </div>
    </>
  );
};

// const App = () => {
//   const [viewport, setViewport] = useState({
//     latitude: 46,
//     longitude: 17,
//     zoom: 3,
//   });
//   const [pins, setPins] = useState([]);
//   const [selectedPin, setSelectedPin] = useState(null);

//   useEffect(() => {
//     const getPins = async () => {
//       try {
//         const res = await axios.get("http://localhost:8800/api/pins");
//         setPins(res.data);
//       } catch (err) {
//         // Handle network errors or other exceptions
//         console.error("Error while fetching data:", err);
//       }
//     };
//     getPins();
//   }, []);

//   const handleMarkerClick = (pin) => {
//     setSelectedPin(pin);
//   };

//   return (
//     <div className="App">
//       <Map
//         {...viewport}
//         width="100vw"
//         height="100vh"
//         onViewportChange={(newViewport) => setViewport(newViewport)}
//         mapboxApiAccessToken={process.env.REACT_APP_MAPBOX}
//         mapStyle="mapbox://styles/cristianbad767/clnyq797h00b101qq1kl2ath0"
//       >
//         {pins.map((pin) => (
//           <Marker key={pin._id} longitude={pin.long} latitude={pin.lat}>
//             <RoomIcon
//               onClick={() => handleMarkerClick(pin)}
//               style={{ fontSize: 40, color: "red", cursor: "pointer" }}
//             />
//           </Marker>
//         ))}

//         {selectedPin && (
//           <Popup
//             longitude={selectedPin.long}
//             latitude={selectedPin.lat}
//             onClose={() => setSelectedPin(null)}
//             anchor="right"
//           >
//             <div className="card">
//               <label>Place</label>
//               <h4 className="place">{selectedPin.title}</h4>
//               <label>Review</label>
//               <p className="description">{selectedPin.desc}</p>
//               <label>Rating</label>
//               <div className="stars">
//                 <Star className="star" />
//                 <Star className="star" />
//                 <Star className="star" />
//                 <Star className="star" />
//                 <Star className="star" />
//               </div>
//               <label>Information</label>
//               <span className="username">
//                 Created by <b>{selectedPin.username}</b>
//               </span>
//             </div>
//           </Popup>
//         )}
//       </Map>
//     </div>
//   );
// };

export default App;
